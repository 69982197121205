@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Medium'), url("229bfa3ff449bcd3b24df8e9745a80d4.woff2") format('woff2'), url("63130d52302b51ede57630c73103b3af.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
.visual {
  position: absolute;
  z-index: 10;
  bottom: 26%;
  left: 18%;
  pointer-events: none;
  font-size: 50px;
  font-size: 65px;
}
@media (min-width: 1280px) and (max-width: 1918px) {
  .visual {
    font-size: calc(50 * 1px + (65 - 50) * (100vw - 1281 * 1px) / (1919 - 1281) );
  }
}
@media (max-width: 1280px) {
  .visual {
    font-size: 30px;
    bottom: 38%;
  }
}
@media (max-width: 1024px) {
  .visual {
    font-size: 24px;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
    top: 32%;
  }
}
@media (max-width: 689px) {
  .visual {
    font-size: 24px;
    font-size: 16px;
  }
}
.visual__friday {
  position: relative;
  z-index: 4;
  width: 12.666666666666666em;
  height: 9.466666666666667em;
}
@media (max-width: 1280px) {
  .visual__friday {
    width: 18.65em;
    height: 13.95em;
  }
}
@media (max-width: 1024px) {
  .visual__friday {
    width: 15.6em;
    height: 10.228571428571428em;
  }
}
@media (max-width: 689px) {
  .visual__friday {
    width: 19.166666666666668em;
    height: 12.416666666666666em;
  }
}
@media (max-width: 414px) {
  .visual__friday {
    width: 13.833333333333334em;
    height: 10.333333333333334em;
  }
}
.visual__date {
  position: absolute;
  top: -46px;
  left: 50px;
  width: 4.216666666666667em;
  height: 2.183333333333333em;
  z-index: 6;
}
@media (max-width: 1280px) {
  .visual__date {
    width: 6em;
    height: 3.1em;
  }
}
@media (max-width: 1280px) {
  .visual__date {
    left: -20px;
    top: -70px;
  }
}
@media (max-width: 689px) {
  .visual__date {
    width: 6.333333333333333em;
    height: 3.25em;
  }
}
@media (max-width: 414px) {
  .visual__date {
    width: 6.333333333333333em;
    height: 3.25em;
  }
}
.visual__info {
  position: absolute;
  right: -4.233333333333333em;
  top: -1.266666666666667em;
  width: 6.166666666666667em;
  height: 230px;
}
@media (max-width: 1280px) {
  .visual__info {
    top: -1.266666666666667em;
    right: -4.233333333333333em;
    width: 6.166666666666667em;
    height: 250px;
  }
}
@media (max-width: 1024px) {
  .visual__info {
    top: -5em;
    right: -4.233333333333333em;
    width: 6.166666666666667em;
    height: 250px;
  }
}
@media (max-width: 689px) {
  .visual__info {
    top: auto;
    bottom: -8.125em;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 7em;
    height: 134px;
  }
}
@media (max-width: 414px) {
  .visual__info {
    width: 7em;
    height: 134px;
    bottom: -5em;
  }
}
.visual__percent {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: 6.166666666666667em;
  height: 230px;
  animation-name: fadein;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
@media (max-width: 689px) {
  .visual__percent {
    width: 7em;
    height: 134px;
    right: auto;
  }
}
.visual__percent.hidden {
  opacity: 0;
}
.visual__percent.visible {
  opacity: 1 !important;
}
.visual__percent.percents-fifty {
  animation-delay: 1.5s;
}
.visual__percent.percents-sixty {
  animation-delay: 3s;
}
.visual__percent.percents-seventy {
  animation-delay: 4.5s;
}
.visual__percent.percents-eighty {
  animation-name: percents-end;
  animation-delay: 6s;
  animation-fill-mode: forwards;
}
.visual__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  text-align: center;
  font-size: 0.666666666666667em;
  line-height: 1.1;
  width: 100%;
  font-weight: 500;
  color: #fff;
}
@media (max-width: 1280px) {
  .visual__text {
    font-size: 1em;
  }
}
@media (max-width: 1024px) {
  .visual__text {
    font-size: 1em;
  }
}
@media (max-width: 689px) {
  .visual__text {
    font-size: 24px;
    top: auto;
    bottom: -1.25em;
    transform: translateX(-50%);
  }
}
.visual__sale {
  text-transform: uppercase;
}
@media (max-width: 1280px) and (min-width: 1023px) and (max-width: 1280px) {
  .visual {
    font-size: calc(30 * 1px + (40 - 30) * (100vw - 1024 * 1px) / (1281 - 1024) );
  }
}
@media (max-width: 1024px) and (min-width: 689px) and (max-width: 1022px) {
  .visual {
    font-size: calc(24 * 1px + (35 - 24) * (100vw - 690 * 1px) / (1023 - 690) );
  }
}
@media (max-width: 689px) and (min-width: 318px) and (max-width: 414px) {
  .visual {
    font-size: calc(16 * 1px + (24 - 16) * (100vw - 319 * 1px) / (415 - 319) );
  }
}
@media (max-width: 689px) and (max-width: 318px) {
  .visual {
    font-size: 16px;
  }
}
@-moz-keyframes percents {
  0% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
  87% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
}
@-webkit-keyframes percents {
  0% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
  87% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
}
@-o-keyframes percents {
  0% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
  87% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
}
@keyframes percents {
  0% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
  87% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
}
@-moz-keyframes percents-end {
  0% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
}
@-webkit-keyframes percents-end {
  0% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
}
@-o-keyframes percents-end {
  0% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
}
@keyframes percents-end {
  0% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
@-o-keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
.navigation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (max-width: 1024px) {
  .navigation {
    bottom: 270px;
  }
}
@media (max-width: 413px) {
  .navigation {
    bottom: 240px;
  }
}
@media (max-width: 359px) {
  .navigation {
    bottom: 190px;
  }
}
.navigation__image-link {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top center !important;
  background-color: #000;
  opacity: 0.5;
}
@media (max-width: 1024px) {
  .navigation__image-link {
    background-size: cover !important;
    background-position: center 0 !important;
    background-repeat: no-repeat !important;
  }
}
@media (max-width: 689px) {
  .navigation__image-link {
    background-position: center 0 !important;
    background-repeat: no-repeat !important;
  }
}
.navigation__image-link.vov {
  z-index: 3;
  background-image: url("cd1196b82ed66afa9ce78998c2beef66.jpg");
  background-size: 3840px auto;
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  .navigation__image-link.vov {
    background-image: url("81669de00cdb8382cbe3de06262ff9c2.jpg");
  }
}
@media (-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi) {
  .navigation__image-link.vov {
    background-image: url("cb94977f444bc8a07839fcde2795a268.jpg");
  }
}
@media (max-width: 1024px) {
  .navigation__image-link.vov {
    background-image: url("21b7db04757c7c0b91e4357d4caaeaf9.jpg");
    background-size: 1600px auto;
  }
}
@media (max-width: 689px) {
  .navigation__image-link.vov {
    background-image: url("f59a5e930834d8cba4a9aa266ef5cdbd.jpg");
    background-size: 375px auto;
  }
}
@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5), (max-width: 1024px) and (min-resolution: 144dpi) {
  .navigation__image-link.vov {
    background-image: url("28a69114cf17fc4aa6bc1587915f0577.jpg");
  }
}
@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2.5), (max-width: 1024px) and (min-resolution: 240dpi) {
  .navigation__image-link.vov {
    background-image: url("afbe40fe7b2c52cd398abe175f4a8101.jpg");
  }
}
@media (max-width: 689px) and (-webkit-min-device-pixel-ratio: 1.5), (max-width: 689px) and (min-resolution: 144dpi) {
  .navigation__image-link.vov {
    background-image: url("2ff14f83dc6ddf6e8fb5cf8040a66a01.jpg");
  }
}
@media (max-width: 689px) and (-webkit-min-device-pixel-ratio: 2.5), (max-width: 689px) and (min-resolution: 240dpi) {
  .navigation__image-link.vov {
    background-image: url("14bfa4ddfdbe51481ef62d8e24faba95.jpg");
  }
}
.navigation__image-link.nrov {
  z-index: 2;
  background-image: url("e56e14fb103f6a065770537e7cc2a535.jpg");
  background-size: 3840px auto;
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  .navigation__image-link.nrov {
    background-image: url("02ada964503792e965b0814b6a0a83db.jpg");
  }
}
@media (-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi) {
  .navigation__image-link.nrov {
    background-image: url("1fc4d7d4f489e606d79599f1ed7a33ea.jpg");
  }
}
@media (max-width: 1024px) {
  .navigation__image-link.nrov {
    background-image: url("18e7151563c1140a3ca3724a496092ea.jpg");
    background-size: 1600px auto;
  }
}
@media (max-width: 689px) {
  .navigation__image-link.nrov {
    background-image: url("cba2e0bdfe6730fa9382fecac8733cfd.jpg");
    background-size: 375px auto;
  }
}
@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5), (max-width: 1024px) and (min-resolution: 144dpi) {
  .navigation__image-link.nrov {
    background-image: url("b428ce4104945f76e99069e6cd228e76.jpg");
  }
}
@media (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2.5), (max-width: 1024px) and (min-resolution: 240dpi) {
  .navigation__image-link.nrov {
    background-image: url("fcaa96f9bae2c000d72c4bddfdc255de.jpg");
  }
}
@media (max-width: 689px) and (-webkit-min-device-pixel-ratio: 1.5), (max-width: 689px) and (min-resolution: 144dpi) {
  .navigation__image-link.nrov {
    background-image: url("c955c747e8aafd738b0a5c3be8ac7f14.jpg");
  }
}
@media (max-width: 689px) and (-webkit-min-device-pixel-ratio: 2.5), (max-width: 689px) and (min-resolution: 240dpi) {
  .navigation__image-link.nrov {
    background-image: url("be56d2dc0b0c438315e56bfa6d7ac0fa.jpg");
  }
}
.navigation__image-link.disabled {
  opacity: 0;
  pointer-events: none;
}
.arrow {
  position: relative;
  display: flex;
  align-items: center;
  width: 40%;
  height: 15px;
  color: currentColor;
}
@media (max-width: 1024px) {
  .arrow {
    width: 1.875rem;
  }
}
.arrow::before {
  width: 15px;
  height: 15px;
  position: absolute;
  content: '';
  border-color: #fff;
  border-style: solid;
  display: block;
  border-width: 0;
  transition: border-color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .arrow::before {
    width: 10px;
    height: 10px;
  }
}
.arrow__line {
  width: 100%;
  height: 1px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.arrow.left {
  margin-left: 1.25rem;
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .arrow.left {
    margin-left: 0;
    margin-top: 1.875rem;
  }
}
.arrow.left::before {
  left: 1px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  border-top-width: 1px;
  border-left-width: 1px;
}
.arrow.left .arrow__line {
  transform-origin: 0 0;
}
.arrow.right {
  margin-right: 1.25rem;
}
@media (max-width: 1024px) {
  .arrow.right {
    margin-right: 0;
    margin-top: 1.875rem;
  }
}
.arrow.right::before {
  right: 1px;
  top: calc(50% + 1px);
  transform: translateY(-50%) rotate(130deg);
  border-top-width: 1px;
  border-left-width: 1px;
}
@media (max-width: 1024px) {
  .arrow.right::before {
    top: 50%;
  }
}
.arrow .arrow__line {
  transform-origin: 100% 0;
}
.switcher {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 14.375rem;
  padding: 0 3.75rem 2.5rem;
  z-index: 10;
}
@media (max-width: 1599px) {
  .switcher {
    padding: 0 2.5rem 2.5rem;
  }
}
@media (max-width: 1280px) {
  .switcher {
    padding: 0 1.875rem 2.5rem;
  }
}
@media (max-width: 1024px) {
  .switcher {
    flex-wrap: wrap;
    padding: 0 0 2.5rem;
  }
}
@media (max-width: 359px) {
  .switcher {
    padding: 0 0 1.25rem;
  }
}
.switcher__button {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .switcher__button {
    padding: 1.5625rem 1.875rem;
    border-top: 1px solid #fff;
  }
}
@media (max-width: 689px) {
  .switcher__button {
    padding: 1.5625rem 0;
  }
}
.switcher__button:hover {
  color: #02af4b;
}
.switcher__button:hover .arrow {
  color: #02af4b;
}
.switcher__button:hover .arrow::before {
  border-color: #02af4b;
}
.switcher__button:hover .arrow__line {
  background-color: #02af4b;
  transform: scale3d(1.05, 1, 1);
}
.switcher__button.vov {
  text-align: left;
  width: 33%;
}
@media (max-width: 1024px) {
  .switcher__button.vov {
    flex-direction: column;
    order: 1;
    width: 50%;
    border-right: 1px solid #fff;
  }
}
.switcher__button.nrov {
  text-align: right;
  width: 31%;
}
@media (max-width: 1024px) {
  .switcher__button.nrov {
    flex-direction: column-reverse;
    order: 2;
    width: 50%;
  }
}
.switcher__button-text {
  width: 50%;
  font-size: 2rem;
  line-height: 2.5625rem;
  text-align: center;
  margin: 0;
}
@media (max-width: 1599px) {
  .switcher__button-text {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}
@media (max-width: 1280px) {
  .switcher__button-text {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}
@media (max-width: 689px) {
  .switcher__button-text {
    font-size: 1.25rem;
    line-height: 1.625rem;
    width: 100%;
  }
}
.switcher__text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 36%;
  padding: 0 1.875rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
}
@media (max-width: 1599px) {
  .switcher__text {
    padding: 0 1.25rem;
    font-size: 1.875rem;
    line-height: 1.875rem;
  }
}
@media (max-width: 1280px) {
  .switcher__text {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
}
@media (max-width: 1024px) {
  .switcher__text {
    order: 0;
    width: 100%;
    flex-grow: 1;
    padding: 1.875rem 1.25rem;
  }
}
@media (max-width: 689px) {
  .switcher__text {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (max-width: 359px) {
  .switcher__text {
    padding: 1.25rem 0.625rem;
  }
}
html {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
}
@media (max-width: 1919px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 1599px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 359px) {
  html {
    font-size: 12px;
  }
}
@media (max-width: 1919px) and (min-width: 1599px) and (max-width: 1919px) {
  html {
    font-size: calc(14 * 1px + (16 - 14) * (100vw - 1600 * 1px) / (1920 - 1600) );
  }
}
@media (max-width: 1599px) and (min-width: 1023px) and (max-width: 1599px) {
  html {
    font-size: calc(13 * 1px + (16 - 13) * (100vw - 1024 * 1px) / (1600 - 1024) );
  }
}
@media (max-width: 359px) and (min-width: 318px) and (max-width: 359px) {
  html {
    font-size: calc(12 * 1px + (20 - 12) * (100vw - 319 * 1px) / (360 - 319) );
  }
}
body {
  font-family: 'Futura PT', Helvetica, Roboto, sans-serif;
  height: 100%;
  margin: 0;
}
* {
  box-sizing: border-box;
}
.landing {
  background-color: #000;
  padding: 6.5rem 6.5rem 0 6.5rem;
}
@media (max-width: 1599px) {
  .landing {
    padding: 4.6875rem 4.6875rem 0 4.6875rem;
  }
}
@media (max-width: 1280px) {
  .landing {
    padding: 3.125rem 3.125rem 0 3.125rem;
  }
}
@media (max-width: 1024px) {
  .landing {
    padding: 2rem 2rem 0 2rem;
  }
}
.landing__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.landing__title {
  color: #fff;
  text-align: center;
  margin-top: 19.375rem;
  font-size: 2.5rem;
  line-height: 3.1875rem;
  font-weight: 400;
  z-index: 2;
}
@media (max-width: 1599px) {
  .landing__title {
    font-size: 2.25rem;
    line-height: 2.625rem;
  }
}
@media (max-width: 1280px) {
  .landing__title {
    font-size: 1.625rem;
    line-height: 2.0625rem;
  }
}
@media (max-width: 689px) {
  .landing__title {
    font-size: 1.5rem;
    line-height: 1.9375rem;
  }
}
